var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"26860d4cf8f698932f37cf269fb203f0696b83be-de"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import * as Spotlight from '@spotlightjs/spotlight';
import { PRODUCTION_ENVIRONMENTS } from '@/app/constants';
import { ignoreErrors } from '@/app/constants/sentrysettings';

const isProduction =
  PRODUCTION_ENVIRONMENTS.includes(process.env.NEXT_PUBLIC_ENVIRONMENT) && process.env.NODE_ENV === 'production';

Sentry.init({
  dsn: 'https://cba3befe2682071014bdafb83a454d77@sentry.mybestbrands.com:8080/13',

  enabled: isProduction,

  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,

  ignoreErrors,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});

if (process.env.NODE_ENV === 'development') {
  Spotlight.init();
}
